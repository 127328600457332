import { PuxMediaSelectorType } from "../components/content/mediaImage/PuxMediaSelector"
import { INewsAuthorItem } from "./data/buildTypedPage"
import { getBuilderService } from "./data/puxBuilderService"
import { puxGraphqlHelper } from "./data/puxGraphqlHelper"

interface ContentItemQueryResult {
  puxAuthor: {
    displayText: string
    path: string
    puxMediaSelector: PuxMediaSelectorType
  }[]
}

export const getNewsAuthorByAuthorId = async (authorId): Promise<INewsAuthorItem> => {
  const { locale } = getBuilderService()

  const contentItemQuery = `
      {
        orchard {
          puxAuthor(where: {puxAuthor: {authorUserId: "${authorId}"}, localization: {culture: "${locale.isoCode.toLowerCase()}"}}) {
            displayText
            path
            puxMediaSelector {
              mediaSelectorImage {
              resizePaths(width: 200, mode: "Min", quality: 75)
              resizePathsMobile(width: 200, mode: "Min", quality: 75)
              resizePathsTablet(width: 200, mode: "Min", quality: 75)
              }
            }
          }
        }
      }
  `

  const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
    errorMetadata:
    {
      message: `Error while running GraphQL query in getNewsAuthor`,
    }
  })

  if (!contentItemNode?.puxAuthor) {
    throw `Error: contentItemNode.contentItem is null or undefined in getNewsAuthor`
  }

  if (contentItemNode?.puxAuthor.length === 0) {
    return {
      image: {
        mediaSelectorImage: {
          resizePaths: [""],
          resizePathsTablet: [""],
          resizePathsMobile: [""],
          texts: ""
        }
      },
      name: "",
      url: "",
    }
  }

  const author: INewsAuthorItem = {
    image: contentItemNode?.puxAuthor[0].puxMediaSelector,
    name: contentItemNode?.puxAuthor[0].displayText,
    url: contentItemNode?.puxAuthor[0].path,
  }

  return author
}
