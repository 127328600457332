export const getHeaderQuery = (isoCode: string) => {
  return `
    {
      orchard {
        menu(where: { alias: { alias_ends_with: "/header-navigation" }, localization: {culture: "${isoCode.toLowerCase()}"} }) {
          menuItemsList {
            menuItems {
              ... on Orchard_ContentMenuItem {
                render
                contentMenuItemDuplicateAlias
                menuItemsList {
                  menuItems {
                    ... on Orchard_PuxMegamenuColumn {
                      contentType
                      menuItemsList {
                        menuItems {
                          ... on Orchard_PuxMegamenuCategoryItem {
                            categoryItemDescription
                            categoryItemColumns
                            categoryItemName
                            menuItemsList {
                              menuItems {
                                ... on Orchard_ContentMenuItem {
                                  contentMenuItemDuplicateAlias
                                  contentMenuItemDuplicateMainItem
                                  contentMenuItemDuplicateHide
                                  contentMenuItemDescription
                                  contentMenuItemArrow
                                  contentMenuItemMediaField {
                                    resizePaths(first: 1)
                                    type(first: 1)
                                    texts
                                  }
                                  contentType
                                  render
                                }
                                ... on Orchard_LinkMenuItem {
                                  contentType
                                  linkMenuItemArrow
                                  linkMenuItemDescription
                                  linkMenuItemMediaField {
                                    resizePaths(first: 1)
                                    type(first: 1)
                                    texts
                                  }
                                  linkMenuItem {
                                    name
                                    url
                                  }
                                }
                                ... on Orchard_HtmlMenuItem {
                                  htmlMenuItem {
                                    html
                                    url
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                menuItemsList {
                  menuItems {
                    ... on Orchard_PuxMegamenuCategoryItem {
                      categoryItemDescription
                      categoryItemColumns
                      categoryItemName
                      menuItemsList {
                        menuItems {
                          ... on Orchard_ContentMenuItem {
                            contentMenuItemDuplicateAlias
                            contentMenuItemDuplicateMainItem
                            contentMenuItemDuplicateHide
                            contentMenuItemDescription
                            contentMenuItemArrow
                            contentMenuItemMediaField {
                              resizePaths(first: 1)
                              type(first: 1)
                              texts
                            }
                            contentType
                            render
                          }
                          ... on Orchard_LinkMenuItem {
                            contentType
                            linkMenuItemArrow
                            linkMenuItemDescription
                            linkMenuItemMediaField {
                              resizePaths(first: 1)
                              type(first: 1)
                              texts
                            }
                            linkMenuItem {
                              name
                              url
                            }
                          }
                          ... on Orchard_HtmlMenuItem {
                            htmlMenuItem {
                              html
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const getFooterQuery = (isoCode: string) => {
  return `
    {
      orchard {
        menu(where: { alias: { alias_ends_with: "/footer-navigation"}, localization: {culture: "${isoCode?.toLowerCase()}" } }) {
          menuItemsList {
            menuItems {
              ... on Orchard_PuxMegamenuCategoryItem {
                categoryItemName
                menuItemsList {
                  menuItems {
                    ... on Orchard_ContentMenuItem {
                      contentType
                      render
                      displayText
                    }
                    ... on Orchard_LinkMenuItem {
                      contentType
                      linkMenuItem {
                        name
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export interface IFooterData {
  menu: FooterMenu[];
}

interface FooterMenu {
  menuItemsList: FooterMenuItemsList;
}

interface FooterMenuItemsList {
  menuItems: FooterCategoryItem[];
}

interface FooterCategoryItem {
  categoryItemName: string;
  menuItemsList: FooterSubMenuItemsList;
}

interface FooterSubMenuItemsList {
  menuItems: FooterSubMenuItem[];
}

interface FooterSubMenuItem {
  contentType: "ContentMenuItem";
  render: string;
  displayText: string;
}









export interface IHeaderData {
  menu: HeaderMenu[];
}

interface HeaderMenu {
  menuItemsList: HeaderMenuItemsList;
}

interface HeaderMenuItemsList {
  menuItems: HeaderMegamenuItem[];
}

interface HeaderMegamenuItem {
  render: string;
  contentMenuItemDuplicateAlias: null | string;
  menuItemsList: HeaderCategoryMenuItemsList | null;
}

interface HeaderCategoryMenuItemsList {
  menuItems: HeaderCategoryMenuItem[] | HeaderColumnItem[];
}

interface HeaderCategoryMenuItem {
  categoryItemDescription: null | string;
  categoryItemColumns: null | string;
  categoryItemName: string;
  menuItemsList: HeaderSubMenuItemsList;
}

interface HeaderColumnItem {
  contentType: "PuxMegamenuColumn";
  menuItemsList: HeaderCategoryMenuItemsList;
}

interface HeaderSubMenuItemsList {
  menuItems: HeaderSubMenuItem[];
}

interface HeaderSubMenuItem {
  contentMenuItemDuplicateAlias?: null;
  contentMenuItemDuplicateMainItem?: boolean;
  contentMenuItemDuplicateHide?: boolean | null;
  contentMenuItemArrow?: boolean;
  contentMenuItemDescription?: string;
  contentMenuItemMediaField?: {
    resizePaths: [string]
    type: [string]
  };
  linkMenuItemArrow?: boolean;
  linkMenuItemDescription?: string;
  linkMenuItemMediaField?: {
    resizePaths: [string]
    type: [string]
  };
  contentType: "ContentMenuItem" | "LinkMenuItem";
  render?: string;
  linkMenuItem?: LinkMenuItem;
}

interface LinkMenuItem {
  name: string;
  url: string;
}
