import './NewsFilter.scss'

import React, { FunctionComponent } from 'react'
import PuxLink from 'Shared/utils/PuxLink'
import { IEasySoftwareNewsCategory } from 'Shared/queries/page-queries'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { t } from 'ttag'

interface INewsFilter {
    data: IEasySoftwareNewsCategory[]
}

const NewsFilter: FunctionComponent<INewsFilter> = (props) => {
    return (
        <div className='NewsFilter'>
            <div className='NewsFilter-title'>
                {t`EasyRedmine.News.Filter.Title`}
            </div>
            <div className='NewsFilter-grid'>
                {props.data.map(category => {
                    return (
                        <PuxLink
                            className={`NewsFilter-item${category.newsCategoryOrder === 0 ? ' NewsFilter-item--all' : ''}`}
                            activeClassName='is-active'
                            to={`${getLocalizedUrl(category.path)}`}
                        >
                            {category.newsCategoryIcon.resizePathsFallback.length > 0 ? (
                                <div className='NewsFilter-itemIcon'>
                                    <svg width="20" height="20">
                                        <mask id={category.displayText.replace(/[^a-z0-9]/gi, '-').toLowerCase()}>
                                            <image xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={category.newsCategoryIcon.resizePathsFallback.find(x => x)}></image>
                                        </mask>
                                        <rect x="0" y="0" width="20" height="20" mask={`url(#${category.displayText.replace(/[^a-z0-9]/gi, '-').toLowerCase()})`} />
                                    </svg>
                                </div>
                            ) : null}
                            {category.newsCategoryOrder === 0 ? t`EasyRedmine.News.Filter.TitleAll` : category.displayText}
                        </PuxLink>
                    )
                })}
            </div>
        </div>
    )
}

export default NewsFilter