export interface IGetRepeaterQuery {
  contentType: RepeaterContentType
  itemIdsArray: string[]
  limit: number
  sort: boolean
  preferedContentLinkCulture: string
}

export type RepeaterContentType =
  | 'EasySoftwareFunction'
  | 'EasySoftwareCaseStudy'
  | 'EasySoftwareNews'
  | 'EasySoftwareFaq'
  | 'EasySoftwareVideoTutorial'
  | 'EasySoftwarePartner'
  | 'EasySoftwareArticle'
  | 'EasySoftwareTestimonial'
  | 'EasySoftwareKnowledgeBaseArticle'

export const getRepeaterQuery = ({
  contentType,
  itemIdsArray,
  limit,
  sort,
  preferedContentLinkCulture = ``,
}: IGetRepeaterQuery) => {
  const itemIdsString = itemIdsArray.map((item) => `"${item}"`).join(`,`)

  switch (contentType) {
    case `EasySoftwareFunction`:
      return `
        easySoftwareFunction(
          where: {contentItemId_in: [${itemIdsString}]},    
          first: ${limit}          
        ) {
          contentType
          contentItemId
          path
          displayText
          functionPerex {
            html
          }
          functionContent {
            html
          }
          functionImage {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
          functionGallery {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
          functionShowMoreLabel {
            html
          }
          functionOpenDetail
          puxVideo {
            videoStructuredDataContentUrl
            videoStructuredDataDescription
            videoStructuredDataName
            videoStructuredDataUploadDate
            videoYTID {
              html
            }
          }
        }
      `

    case `EasySoftwareCaseStudy`:
      return `
        easySoftwareCaseStudy(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
        ) {
          contentType
          contentItemId
          path
          displayText
          caseStudyPerex {
            html
          }
          caseStudyImage {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
          caseStudyParameters {
            contentItems(first: 1) {
              ... on Orchard_PuxKeyValue {
                displayText
                contentItemId
                keyValueContent {
                  html
                }
              }
            }
          }
        }
      `

    case `EasySoftwareNews`:
      return `
        easySoftwareNews(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit},
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          path
          displayText
          createdUtc
          newsContent {
            html
          }
          newsPerex {
            html
          }
          newsCategory {
            termContentItems {
              displayText
              ... on Orchard_PuxCategory {
                categoryContentLink {
                  url(culture: "${preferedContentLinkCulture}")
                }
              }
            }
            termContentItemIds
          }
          newsTags {
            termContentItems {
              displayText
              ... on Orchard_PuxCategory {
                categoryContentLink {
                  url(culture: "${preferedContentLinkCulture}")
                }
              }
            }
            termContentItemIds
          }
          newsImage {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
          puxVideo {
            videoStructuredDataContentUrl
            videoStructuredDataDescription
            videoStructuredDataName
            videoStructuredDataUploadDate
            videoThumbnail {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
            videoYTID {
              html
            }
          }
        }
      `

    case `EasySoftwareFaq`:
      return `
        easySoftwareFaq(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          path
          displayText
          createdUtc
          faqAnswer {
            html
          }
        }
        `

    case `EasySoftwareVideoTutorial`:
      return `
        easySoftwareVideoTutorial(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          displayText
          createdUtc
          puxVideo {
            videoStructuredDataContentUrl
            videoStructuredDataDescription
            videoStructuredDataName
            videoStructuredDataUploadDate
            videoYTID {
              html
            }
          }
          videoTutorialPerex {
            html
          }
          videoTutorialSolution
          videoTutorialLevel
          videoTutorialRole
          videoTutorialImage {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
        }
        `

    case `EasySoftwarePartner`:
      return `
        easySoftwarePartner(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          displayText
          createdUtc
          partnerCountry
          partnerIndustry
          partnerRegion
          partnerLevel
          partnerLogo {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
          partnerContact {
            html
          }
          partnerText {
            html
          }
          partnerWebsiteUrl {
            text
            url
          }
        }
        `

    case `EasySoftwareTestimonial`:
      return `
        easySoftwareTestimonial(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          testimonialName {
            html
          }
          testimonialOccupation {
            html
          }
          testimonialCompany {
            html
          }
          testimonialText {
            html
          }
          testimonialPhoto {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80)
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
        }
        `

    case `EasySoftwareArticle`:
      return `
        easySoftwareArticle(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          displayText
          path
          articlePerex {
            html
          }
          puxVideo {
            videoStructuredDataContentUrl
            videoStructuredDataDescription
            videoStructuredDataName
            videoStructuredDataUploadDate
            videoYTID {
              html
            }
          }
          articleImage {
            mediaSelectorImage {
              resizePaths(width: 1920, mode: "Min", quality: 80) 
              resizePathsMobile(width: 768, mode: "Min", quality: 80)
              resizePathsTablet(width: 1200, mode: "Min", quality: 80)
              texts
            }
          }
        }
        `

    case `EasySoftwareKnowledgeBaseArticle`:
      return `
        easySoftwareKnowledgeBaseArticle(
          where: {contentItemId_in: [${itemIdsString}]},
          first: ${limit}
          ${sort ? `orderBy: {createdUtc: DESC}` : ``}
        ) {
          contentType
          contentItemId
          displayText
          path
        }
        `

    default:
      return ``
  }
}
