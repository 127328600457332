import React, { useEffect } from 'react'
import { IHeaderData } from 'Root/Shared/queries/layout-queries'
import { useEditorStatus } from 'Root/Shared/utils/edit/useEditorStatus'
import { usePreviewStatus } from 'Root/Shared/utils/edit/usePreviewStatus'
import GoogleSearch from 'Shared/components/GoogleSearch/GoogleSearch'
import PuxButton from 'Shared/components/PuxButton/PuxButton'
import PuxLanguageSelector, {
  localizedPathType,
} from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import { getFromConfig } from 'Shared/utils/localeURL'
import PuxLink from 'Shared/utils/PuxLink'
import useWindowDimensions from 'Shared/utils/useWindowDimensions'
import { t } from 'ttag'

//@ts-ignore
import { ReactComponent as Logo } from '../../svg/EP-logo.svg'
import HeaderMobileNav, { HeaderData } from 'Shared/components/header/HeaderMobileNav'
import HeaderNav from 'Shared/components/header/HeaderNav'

const localizedHomepageUrl = `/${getFromConfig(
  process.env.LOCALE ?? `en`,
  `urlPrefix`,
  process.env.PROJECT ?? `EasyProjectGatsby`
)}`

interface HeaderPropsType {
  customHeaderClass?: string
  customHeaderFnc: (duplicatedNav: boolean) => void
  style?: string
  cta?: LinkType
  landingPageNav?: HeaderData
  localizedPaths?: localizedPathType[]
  headerData: IHeaderData
}

const Header: React.FunctionComponent<HeaderPropsType> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isScrolled, setScrolled] = React.useState(false)
  const headerData: HeaderData | any = props.headerData.menu[0].menuItemsList
  const { width } = useWindowDimensions()
  const localizedPaths = props.localizedPaths
  const isDesktop = width > 1199 || width === 0
  const isRespo = width <= 1199 && width !== 0
  const isEditor = useEditorStatus()
  const isPreview = usePreviewStatus()

  const classList = [`Header`]

  if (isOpen) classList.push(`is-opened`)
  if (isScrolled) classList.push(`is-scrolled`)
  if (props.style === `landing`) classList.push(`Header--landing`)
  if (props.customHeaderClass) classList.push(props.customHeaderClass)

  const updateDimensions = () => {
    if (window.scrollY > 0) {
      return setScrolled(true)
    }

    return setScrolled(false)
  }

  useEffect(() => {
    document
      .querySelectorAll(`.Header-menuRespo a`)
      .forEach((link) => link.addEventListener(`click`, () => setIsOpen(false)))
  })

  useEffect(() => {
    window.addEventListener(`scroll`, updateDimensions)

    return () => {
      window.removeEventListener(`scroll`, updateDimensions)
    }
  }, [isScrolled])

  return (
    <header className={classList.join(` `)}>
      <div className='Header-container'>
        <PuxLink className='Header-logo' to={localizedHomepageUrl}>
          <Logo />
        </PuxLink>
        {isDesktop && (
          <HeaderNav
            headerData={
              props.landingPageNav ? props.landingPageNav : headerData
            }
          />
        )}
        <div className='Header-trial'>
          {t`Redmine.Header.ContactSales` !== `Redmine.Header.ContactSales` &&
            t`Redmine.Header.ContactSales.Link` !==
            `Redmine.Header.ContactSales.Link` ? (
            width > 1499 ?
              (<PuxButton
                buttonType='Button--secondary'
                buttonSize='Button--medium'
                buttonColor='Button--blue'
                buttonLink={{
                  url: [t`Redmine.Header.ContactSales.Link`],
                  internal: [false],
                  text: [t`Redmine.Header.ContactSales`],
                }}
              />)
              :
              (<a
                href={[t`Redmine.Header.ContactSales.Link`]}
                className={`Header-sales`}
              />)
          ) : null}
          <PuxButton
            buttonType='Button--primary'
            buttonSize='Button--medium'
            buttonColor='Button--yellow'
            buttonLink={{
              url: [props.cta ? props.cta.url : `#trial`],
              internal: [false],
              text: [props.cta ? props.cta.text : t`Redmine.Header.FreeTrial`],
            }}
          />
        </div>
        {isDesktop && (
          <>
            <a
              href='https://client-zone.easysoftware.com/en/login'
              className={`Header-user`}
            />
            {!(isEditor || isPreview) && (
              <div className={`Header-search`}>
                <GoogleSearch searchType='header' />
              </div>
            )}
            <div className={`Header-language`}>
              <PuxLanguageSelector localizedPaths={localizedPaths} />
            </div>
          </>
        )}
        {isRespo && (
          <div className={`Header-burger`} onClick={() => setIsOpen(!isOpen)}>
            <span className='Header-burgerIcon Header-burgerIcon--open'></span>
            <span className='Header-burgerIcon Header-burgerIcon--close'></span>
          </div>
        )}
      </div>
      {isRespo && (
        <div className='Header-respoWrapper'>
          <GoogleSearch searchType='headerRespo' />
          <HeaderMobileNav
            headerData={
              props.landingPageNav ? props.landingPageNav : headerData
            }
          />
          <div className='Header-respoWrapperFooter'>
            <div>
              {t`Redmine.Header.ContactSales` !==
                `Redmine.Header.ContactSales` &&
                t`Redmine.Header.ContactSales.Link` !==
                `Redmine.Header.ContactSales.Link` ? (
                <PuxButton
                  buttonType='Button--secondary'
                  buttonSize='Button--medium'
                  buttonColor='Button--blue'
                  buttonLink={{
                    url: [t`Redmine.Header.ContactSales.Link`],
                    internal: [false],
                    text: [t`Redmine.Header.ContactSales`],
                  }}
                />
              ) : null}
              <a
                href='https://client-zone.easysoftware.com/en/login'
                className={`Header-user`}
              />
            </div>
            <div className={`Header-language`}>
              <PuxLanguageSelector
                localizedPaths={localizedPaths}
                selectorType='nativeDropdown'
              />
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
