import { IEasySoftwareNewsCategory } from "../queries/page-queries"
import { getBuilderService } from "./data/puxBuilderService"
import { puxGraphqlHelper } from "./data/puxGraphqlHelper"

interface ContentItemQueryResult {
  easySoftwareNewsCategory: IEasySoftwareNewsCategory[]
}

export const getNewsCategories = async (): Promise<IEasySoftwareNewsCategory[]> => {
  const { locale } = getBuilderService()

  const contentItemQuery = `
      {
        orchard {
          easySoftwareNewsCategory(where: {localization: {culture: "${locale.isoCode.toLowerCase()}"}}) {
            displayText
            path
            newsCategoryOrder
            newsCategoryIcon {
              resizePathsFallback(first: 1)
            }
          }
        }
      }
  `

  const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
    errorMetadata:
    {
      message: `Error while running GraphQL query in getNewsCategories`,
    }
  })

  if (!contentItemNode?.easySoftwareNewsCategory) {
    throw `Error: contentItemNode.contentItem is null or undefined in getNewsCategories`
  }

  const orderedCategories = contentItemNode.easySoftwareNewsCategory.sort((a, b) => {


    return (a.newsCategoryOrder !== null ? a.newsCategoryOrder : 1) - (b.newsCategoryOrder !== null ? b.newsCategoryOrder : 1)
  })

  return orderedCategories
}
