import { getBuilderService } from './puxBuilderService'
import { removeOrchardPrefixFromQuery } from './removeOrchardPrefixFromQuery'

interface PuxGraphqlHelperOptions {
    hasOrchardPrefix?: boolean
    errorMetadata?: PuxErrorMetadata
}

interface PuxErrorMetadata {
    message?: string
    metadata?: string
    fromFunction?: string
}

interface HasOrchardPrefix<T> {
    orchard: T
}

interface graphqlResult<T> {
    errors?: any
    data?: T
}

export async function puxGraphqlHelper<T>(query: string, options?: PuxGraphqlHelperOptions): Promise<T> {
    const hasOrchardPrefix = options?.hasOrchardPrefix ?? getBuilderService().hasOrchardPrefix ?? true

    let result: graphqlResult<HasOrchardPrefix<T>> | graphqlResult<T>

    if (hasOrchardPrefix) {
        result = await getGraphqlResult<HasOrchardPrefix<T>>(query, options)

        if (result.data === undefined || result.data.orchard === undefined) {
            throw puxGraphqlErrorReporter(result, query, options?.errorMetadata)
        }

        return result.data.orchard
    }

    result = await getGraphqlResult<T>(removeOrchardPrefixFromQuery(query), options)

    if (result.data === undefined) {
        throw puxGraphqlErrorReporter(result, query, options?.errorMetadata)
    }

    return result.data

}

async function getGraphqlResult<T>(query: string, options?: PuxGraphqlHelperOptions) {
    const graphql = getBuilderService().builderArgs.graphql

    var result = await graphql<T>(query)

    if (result.errors) {
        throw puxGraphqlErrorReporter(result, query, options?.errorMetadata)
    }

    return result
}

function puxGraphqlErrorReporter(result: unknown, query: string, errorMetadata: PuxErrorMetadata | undefined) {
    const reporter = getBuilderService().builderArgs.reporter

    // let message = errorMetadata?.message ?? `Error while running GraphQL query.`

    // if (errorMetadata?.fromFunction) {
    //     message += `
    //         From function: ${errorMetadata.fromFunction}
    //     `
    // }

    // if (errorMetadata?.metadata) {
    //     message += `
    //         Metadata: ${errorMetadata.metadata}
    //     `
    // }

    // if (!message.includes("Query:") && query) {
    //     message += `
    //         Query: ${query}
    //     `
    // }

    // reporter.panicOnBuild(message)
    reporter.panicOnBuild("")

    // console.log(JSON.stringify(result))
}