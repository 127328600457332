import { getBaseQuery } from "../../queries/base-queries"
import { IEasySoftwareNewsCategory, IEasySoftwareNewsTag, PageType, getPageQuery } from '../../queries/page-queries'
import { getBuilderService } from "./puxBuilderService"
import { puxGraphqlHelper } from "./puxGraphqlHelper"
import { WidgetPuxSectionType } from '../../components/widgets/WidgetPuxSection'
import { getWidgetsFromOrchardWidgetZone } from './getWidgetsFromOrchardWidgetZone'
import { getLocalizedUrl } from '../localeURL'
import getSideCardWidgets, { getSpecificSideCardWidgets } from './getSideCardData'
import getChangeLogData from '../getChangeLogData'
import getKnowledgeBaseData from '../getKnowledgeBaseData'
import { IBuildTypedPages, IPageContext } from './buildTypedPage'
import { getNewsRelatedItems } from "../getNewsRelatedItems"
import { getRepeaterData } from "./getRepeaterData"
import { WidgetPuxRepeaterType } from "Root/Shared/components/widgets/WidgetPuxRepeater"
import { getNewsCategories } from "../getNewsCategories"
import { getNewsContentIds, getNewsContentIdsByAuthorId } from "../getNewsContentIds"
import { TaxonomyContentItemType } from "Root/Shared/components/PuxRepeater/PuxRepeater"
import { getNewsTags } from "../getNewsTags"
import { getNewsAuthorByAuthorId } from "../getNewsAuthor"
import { getBreadcrumbs } from "../getBreadCrumbs"


export const getTypedData = async (page: PageType, settings: IBuildTypedPages, isPreview: boolean | undefined = undefined) => {
    const { locale } = getBuilderService()
    const newsCategoriesCache: IEasySoftwareNewsCategory[] = await getNewsCategories()
    const newsTagsCache: IEasySoftwareNewsTag[] = await getNewsTags()

    const path = page.path

    if (!path.startsWith(locale.isoCode)) {
        console.log(
            `WARNING: Invalid path of node: ${path} ${page.contentItemId}`
        )
    }

    const context: Partial<IPageContext<PageType>> = {
        pagePath: path,
    }

    if (settings.template.widgetZones && settings.template.widgetZones.length) {
        context.widgets = {}

        if (isPreview === true) {
            await Promise.all(
                settings.template.widgetZones.map(async (widgetZone) => {
                    return new Promise(async (resolve) => {
                        const baseQueryWithoutMeta = getBaseQuery({
                            contentType: settings.template.contentType,
                            sections: [widgetZone],
                            addPuxCategoryToQuery: false,
                            addMetaTags: false,
                            customBreadCrumbsTax: undefined
                        })

                        const widgets = await getWidgetsFromOrchardWidgetZone({
                            zone: page[widgetZone],
                            baseQueryForWidget: baseQueryWithoutMeta,
                            path: path,
                            documentType: settings.template.contentType,
                            widgetZoneName: widgetZone,
                            isPreview: true,
                            versionId: page.contentItemVersionId
                        })

                        widgets.map(sections => sections?.map(section => section?.flow?.widgets?.map(widget => {
                            if (widget.contentType === "PuxSimpleRepeater") {
                                widget.repeaterData?.map(part => {
                                    if (part.contentType === "EasySoftwareNews") {
                                        const category = newsCategoriesCache?.find(category => {
                                            return category.path === part.newsCategory?.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)
                                        })

                                        part.newsCategoryLabel = {
                                            icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                                            title: category?.displayText ?? "",
                                            url: category?.path ?? "",
                                            order: category?.newsCategoryOrder ?? 0
                                        }
                                    }
                                })
                            }
                        })))

                        if (context.widgets) {
                            context.widgets[widgetZone] = widgets.map(
                                (widgetsGroup) => widgetsGroup[0]
                            )
                        }
                        resolve(true)
                    })
                })
            )
        } else {
            await settings.template.widgetZones.map(async (widgetZone) => {
                const baseQueryWithoutMeta = getBaseQuery({
                    contentType: settings.template.contentType,
                    sections: [widgetZone],
                    addPuxCategoryToQuery: false,
                    addMetaTags: false,
                    customBreadCrumbsTax: undefined
                })

                const widgets = await getWidgetsFromOrchardWidgetZone({
                    zone: page[widgetZone],
                    baseQueryForWidget: baseQueryWithoutMeta,
                    path: path,
                    documentType: settings.template.contentType,
                    widgetZoneName: widgetZone,
                    isPreview: false,
                    versionId: page.contentItemVersionId
                })

                if (context.widgets) {
                    context.widgets[widgetZone] = widgets
                }
            })
        }
    }

    const specificSideCardWidgets: WidgetPuxSectionType[] = []
    const generalSideCardWidgets: WidgetPuxSectionType[] = []

    if (settings.addons.addSideCards) {
        if (page.contentItemId) {
            const sideCardWidgets = await getSpecificSideCardWidgets(
                "",
                page.contentItemId
            )

            sideCardWidgets.map((widget) => {
                specificSideCardWidgets.push(widget)
            })
        }
    }

    if (
        settings.addons.addSideCards &&
        page?.puxCategory &&
        page?.puxCategory.termContentItemIds // null check
    ) {
        const pageTermContentItemId =
            page.puxCategory.termContentItemIds[0] != null
                ? page.puxCategory.termContentItemIds[0]
                : null

        if (pageTermContentItemId) {
            const sideCardWidgets = await getSideCardWidgets(
                "",
                pageTermContentItemId
            )
            sideCardWidgets.map((widget) => {
                generalSideCardWidgets.push(widget)
            })
        }
    }

    context.sideCardWidgets = [
        ...specificSideCardWidgets,
        ...generalSideCardWidgets,
    ]

    const localizedPath = getLocalizedUrl(path)
    // context.isoCode = localizedConfigData.isoCode

    if (settings.addons.addMetaTags) {
        context.metaTags = page.puxMetaTags

        if (
            !context.metaTags?.metaTagsTitle.html ||
            !context.metaTags?.metaTagsTitle.html.length
        ) {
            context.metaTags.metaTagsTitle = { html: page.displayText }
        }

        if (
            !context.metaTags?.metaTagsCanonicalUrl ||
            !context.metaTags?.metaTagsCanonicalUrl.html ||
            !context.metaTags?.metaTagsCanonicalUrl.html.length
        ) {
            context.metaTags.metaTagsCanonicalUrl = {
                html: localizedPath,
            }
        }
    }

    if (settings.addons.addBreadCrumbs) {
        const customBreadcrumbsExist = settings.addons.customBreadCrumbsTax
            ? page[settings.addons.customBreadCrumbsTax] &&
            page[settings.addons.customBreadCrumbsTax].taxonomyContentItemId &&
            page[settings.addons.customBreadCrumbsTax].termContentItemIds[0]
            : false


        if (customBreadcrumbsExist && settings.addons.customBreadCrumbsTax) {
            const breadCrumbsData = await getBreadcrumbs(
                page[settings.addons.customBreadCrumbsTax],
                path,
                page.displayText
            )

            context.breadcrumbs = breadCrumbsData
        } else {
            const breadCrumbsData = await getBreadcrumbs(
                page.puxCategory,
                path,
                page.displayText
            )

            context.breadcrumbs = breadCrumbsData
        }
    }

    // TODO: move getChangeLogData() function out of loop
    if (page.contentType === `EasySoftwareChangeLog`) {
        const changeLogData = await getChangeLogData()

        context.changeLog = changeLogData
    }

    if (page.contentType === `EasySoftwareKnowledgeBase`) {
        const knowledgeBaseData = await getKnowledgeBaseData()

        context.knowledgeBaseData = knowledgeBaseData
    }

    if (page.contentType === `EasySoftwareKnowledgeBaseCategory`) {
        const knowledgeBaseData = await getKnowledgeBaseData(
            path
        )

        context.knowledgeBaseData = knowledgeBaseData
    }

    if (page.contentType === `EasySoftwareKnowledgeBaseArticle`) {
        const getKnowledgeBaseArticleBreadcrumbs = () => {
            if (context.breadcrumbs && context.breadcrumbs.length) {
                if ([...context.breadcrumbs]?.reverse()?.length > 1) {
                    return [...context.breadcrumbs].reverse()[1].path
                }
            }

            return undefined
        }

        const knowledgeBaseData = await getKnowledgeBaseData(
            getKnowledgeBaseArticleBreadcrumbs()
        )

        context.knowledgeBaseData = knowledgeBaseData
    }

    const pageDataQuery = getPageQuery(page.contentType, page.path, isPreview, locale.preferedContentLinkCulture.toLowerCase())
    if (pageDataQuery) {
        const result = await puxGraphqlHelper<PageType>(
            pageDataQuery,
            {
                errorMetadata: {
                    fromFunction: `Error while running GraphQL query for ${page.path} in buildTypedPage.ts`,
                }
            }
        )

        // change first char in string to lowercase
        const contentTypeCamelCase = page.contentType.charAt(0).toLowerCase() + page.contentType.slice(1)

        context.pageData = { ...result[contentTypeCamelCase][0], contentItemId: page.contentItemId }
    }

    if (page.contentType === `EasySoftwareNews` && context.pageData?.contentType === "EasySoftwareNews") {
        context.newsTags = []
        newsTagsCache?.map(tag => {
            context?.pageData?.newsTags?.termContentItems?.map((item) => {
                if (item?.categoryContentLink?.url?.includes(tag.path)) {
                    context.newsTags?.push({
                        title: tag?.displayText ?? "",
                        url: tag?.path ?? "",
                    })
                }
            })


        })

        context.newsCategories = []
        newsCategoriesCache?.map(category => {
            if (category.path === context?.pageData?.newsCategory?.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)) {
                context.newsCategories?.push({
                    icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                    title: category?.displayText ?? "",
                    url: category?.path ?? "",
                    order: category?.newsCategoryOrder ?? 0
                })
            }
        })

        if (context?.pageData?.puxAuthor?.authorId) {
            const author = await getNewsAuthorByAuthorId(context?.pageData?.puxAuthor?.authorId)
            context.newsAuthor = author
        }

        if (context.pageData.newsRelatedItems) {

            const newsRelatedItems = await getNewsRelatedItems(context.pageData.newsRelatedItems.contentItemIds)

            newsRelatedItems.map(part => {
                const category = newsCategoriesCache?.find(category => {
                    return category.path === part.newsCategory?.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)
                })

                part.newsCategoryLabel = {
                    icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                    title: category?.displayText ?? "",
                    url: category?.path ?? "",
                    order: category?.newsCategoryOrder ?? 0
                }
            })

            context.newsData = newsRelatedItems
        }
    }

    if (page.contentType === `PuxAuthor` && context.pageData?.contentType === "PuxAuthor") {
        context.newsCategories = []
        let repeaterData: TaxonomyContentItemType[] = []

        const repeaterOptions: WidgetPuxRepeaterType = {
            contentType: "PuxSimpleRepeater",
            widgetOrder: 0,
            repeaterData: repeaterData,
            simpleRepeaterPageSize: 9,
            simpleRepeaterPagerOptions: "pages",
            simpleRepeaterOnePageOnly: false,
            simpleRepeaterFallbackCulture: {
                html: ""
            },
            puxSimpleFilter: {
                simpleFilterTermIDs: { termContentItemIds: [] },
                simpleFilterExplicitIDs: undefined,
                simpleFilterContentType: "EasySoftwareNews",
                simpleFilterSortByIDs: false,
                puxFilterIsHidden: true
            },
            metadata: { alignment: "", size: 100 },
            puxWidgetProperties: {
                widgetPropertiesPartID: "",
                widgetPropertiesPartMarginBottom: "",
                widgetPropertiesPartMarginTop: "",
                widgetPropertiesPartPaddingBottom: "",
                widgetPropertiesPartPaddingTop: "",
                widgetPropertiesPartMobileOrder: ""
            },
            puxWidgetAnimation: {
                widgetAnimationPartIsEnabled: false,
                widgetAnimationPartType: "",
                widgetAnimationPartFraction: "",
                widgetAnimationPartDelay: "",
                widgetAnimationPartDuration: ""
            }
        }

        const contentIds = await getNewsContentIdsByAuthorId(context.pageData.puxAuthor.authorId)
        const data = await getNewsRelatedItems(contentIds, contentIds.length, true, locale.preferedContentLinkCulture)
        const newRepeaterOptions = repeaterOptions

        data.map(part => {
            const category = newsCategoriesCache?.find(category => {
                return category.path === part.newsCategory?.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)
            })

            part.newsCategoryLabel = {
                icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                title: category?.displayText ?? "",
                url: category?.path ?? "",
                order: category?.newsCategoryOrder ?? 0
            }
        })

        newRepeaterOptions.repeaterData = data

        context.newsRepeater = newRepeaterOptions
    }

    if (page.contentType === `EasySoftwareNewsCategory` && context.pageData?.contentType === "EasySoftwareNewsCategory") {
        let repeaterData: TaxonomyContentItemType[] = []

        const repeaterOptions: WidgetPuxRepeaterType = {
            contentType: "PuxSimpleRepeater",
            widgetOrder: 0,
            repeaterData: repeaterData,
            simpleRepeaterPageSize: 9,
            simpleRepeaterPagerOptions: "pages",
            simpleRepeaterOnePageOnly: false,
            simpleRepeaterFallbackCulture: {
                html: ""
            },
            puxSimpleFilter: {
                simpleFilterTermIDs: context.pageData?.newsCategoryListingTaxonomy ?? { termContentItemIds: [] },
                simpleFilterExplicitIDs: undefined,
                simpleFilterContentType: "EasySoftwareNews",
                simpleFilterSortByIDs: false,
                puxFilterIsHidden: true
            },
            metadata: { alignment: "", size: 100 },
            puxWidgetProperties: {
                widgetPropertiesPartID: "",
                widgetPropertiesPartMarginBottom: "",
                widgetPropertiesPartMarginTop: "",
                widgetPropertiesPartPaddingBottom: "",
                widgetPropertiesPartPaddingTop: "",
                widgetPropertiesPartMobileOrder: ""
            },
            puxWidgetAnimation: {
                widgetAnimationPartIsEnabled: false,
                widgetAnimationPartType: "",
                widgetAnimationPartFraction: "",
                widgetAnimationPartDelay: "",
                widgetAnimationPartDuration: ""
            }
        }

        if (context.pageData.newsCategoryOrder === 0) {
            const contentIds = await getNewsContentIds()
            const data = await getNewsRelatedItems(contentIds, contentIds.length, true)
            const newRepeaterOptions = repeaterOptions

            data.map(part => {
                const category = newsCategoriesCache?.find(category => {
                    return category.path === part?.newsCategory?.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)
                })

                part.newsCategoryLabel = {
                    icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                    title: category?.displayText ?? "",
                    url: category?.path ?? "",
                    order: category?.newsCategoryOrder ?? 0
                }
            })

            newRepeaterOptions.repeaterData = data

            context.newsRepeater = newRepeaterOptions
        } else {
            const data = await getRepeaterData(repeaterOptions)

            data?.repeaterData.map((part) => {
                if (part.contentType === "EasySoftwareNews") {
                    const category = newsCategoriesCache?.find(category => {
                        return category.path === part.newsCategory.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)
                    })

                    part.newsCategoryLabel = {
                        icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                        title: category?.displayText ?? "",
                        url: category?.path ?? "",
                        order: category?.newsCategoryOrder ?? 0
                    }
                }
            })

            context.newsRepeater = data
        }
        context.newsCategories = newsCategoriesCache
    }

    if (page.contentType === `EasySoftwareNewsTag` && context.pageData?.contentType === "EasySoftwareNewsTag") {
        let repeaterData: TaxonomyContentItemType[] = []

        const repeaterOptions: WidgetPuxRepeaterType = {
            contentType: "PuxSimpleRepeater",
            widgetOrder: 0,
            repeaterData: repeaterData,
            simpleRepeaterPageSize: 9,
            simpleRepeaterPagerOptions: "pages",
            simpleRepeaterOnePageOnly: false,
            simpleRepeaterFallbackCulture: {
                html: ""
            },
            puxSimpleFilter: {
                simpleFilterTermIDs: context.pageData?.newsTagListingTaxonomy ?? { termContentItemIds: [] },
                simpleFilterExplicitIDs: undefined,
                simpleFilterContentType: "EasySoftwareNews",
                simpleFilterSortByIDs: false,
                puxFilterIsHidden: true
            },
            metadata: { alignment: "", size: 100 },
            puxWidgetProperties: {
                widgetPropertiesPartID: "",
                widgetPropertiesPartMarginBottom: "",
                widgetPropertiesPartMarginTop: "",
                widgetPropertiesPartPaddingBottom: "",
                widgetPropertiesPartPaddingTop: "",
                widgetPropertiesPartMobileOrder: ""
            },
            puxWidgetAnimation: {
                widgetAnimationPartIsEnabled: false,
                widgetAnimationPartType: "",
                widgetAnimationPartFraction: "",
                widgetAnimationPartDelay: "",
                widgetAnimationPartDuration: ""
            }
        }

        const data = await getRepeaterData(repeaterOptions)

        data?.repeaterData.map((part) => {
            if (part.contentType === "EasySoftwareNews") {
                const category = newsCategoriesCache?.find(category => {
                    return category.path === part.newsCategory?.termContentItems?.find(x => x)?.categoryContentLink?.url?.find(x => x)
                })

                part.newsCategoryLabel = {
                    icon: category?.newsCategoryIcon?.resizePathsFallback.join(''),
                    title: category?.displayText ?? "",
                    url: category?.path ?? "",
                    order: category?.newsCategoryOrder ?? 0
                }
            }
        })

        context.newsRepeater = data

        context.newsCategories = newsCategoriesCache
    }

    context.headerData = settings.layout.headerData
    context.footerData = settings.layout.footerData

    return {
        localizedPath,
        context,
    }
}