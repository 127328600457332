import { graphql, useStaticQuery } from 'gatsby'
import { getLocalizedData } from 'Shared/utils/localeURL.ts'
const localizedConfigData = getLocalizedData(
  process.env.LOCALE,
  process.env.PROJECT
)

// TODO: We can/should not use this approach, because StaticQuery has no variables, we need to fetch all language variant data in layout query.
export const useHeaderData = () => {
  const {
    orchard: { menu },
  } = useStaticQuery(
    graphql`
      {
        orchard {
          menu(where: { alias: { alias_ends_with: "/header-navigation" } }) {
            displayText
            alias {
              alias
            }
            menuItemsList {
              menuItems {
                ... on Orchard_ContentMenuItem {
                  render
                  contentMenuItemDuplicateAlias
                  menuItemsList {
                    menuItems {
                      ... on Orchard_PuxMegamenuColumn {
                        contentType
                        menuItemsList {
                          menuItems {
                            ... on Orchard_PuxMegamenuCategoryItem {
                              categoryItemDescription
                              categoryItemColumns
                              categoryItemName
                              menuItemsList {
                                menuItems {
                                  ... on Orchard_ContentMenuItem {
                                    contentMenuItemDuplicateAlias
                                    contentMenuItemDuplicateMainItem
                                    contentMenuItemDuplicateHide
                                    contentMenuItemDescription
                                    contentMenuItemArrow
                                    contentMenuItemMediaField {
                                      resizePaths(first: 1)
                                      type(first: 1)
                                    }
                                    contentType
                                    render
                                  }
                                  ... on Orchard_LinkMenuItem {
                                    contentType
                                    linkMenuItemArrow
                                    linkMenuItemMediaField {
                                      resizePaths(first: 1)
                                      type(first: 1)
                                    }
                                    linkMenuItem {
                                      name
                                      url
                                    }
                                  }
                                  ... on Orchard_HtmlMenuItem {
                                    htmlMenuItem {
                                      html
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  menuItemsList {
                    menuItems {
                      ... on Orchard_PuxMegamenuCategoryItem {
                        categoryItemDescription
                        categoryItemColumns
                        categoryItemName
                        menuItemsList {
                          menuItems {
                            ... on Orchard_ContentMenuItem {
                              contentMenuItemDuplicateAlias
                              contentMenuItemDuplicateMainItem
                              contentMenuItemDuplicateHide
                              contentMenuItemDescription
                              contentMenuItemArrow
                              contentMenuItemMediaField {
                                resizePaths(first: 1)
                                type(first: 1)
                              }
                              contentType
                              render
                            }
                            ... on Orchard_LinkMenuItem {
                              contentType
                              linkMenuItemArrow
                              linkMenuItemMediaField {
                                resizePaths(first: 1)
                                type(first: 1)
                              }
                              linkMenuItem {
                                name
                                url
                              }
                            }
                            ... on Orchard_HtmlMenuItem {
                              htmlMenuItem {
                                html
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  var languageMenu = menu.filter(
    (m) => m.alias.alias.indexOf(localizedConfigData.isoCode) === 0
  )

  return languageMenu && languageMenu.length
    ? languageMenu[0].menuItemsList
    : menu[0].menuItemsList
}
