import { IEasySoftwareNewsTag } from "../queries/page-queries"
import { getBuilderService } from "./data/puxBuilderService"
import { puxGraphqlHelper } from "./data/puxGraphqlHelper"

interface ContentItemQueryResult {
  easySoftwareNewsTag: IEasySoftwareNewsTag[]
}

export const getNewsTags = async (): Promise<IEasySoftwareNewsTag[]> => {
  const { locale } = getBuilderService()

  const contentItemQuery = `
      {
        orchard {
          easySoftwareNewsTag(where: {localization: {culture: "${locale.isoCode.toLowerCase()}"}}) {
            displayText
            path
          }
        }
      }
  `

  const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
    errorMetadata:
    {
      message: `Error while running GraphQL query in getNewsTags`,
    }
  })

  if (!contentItemNode?.easySoftwareNewsTag) {
    throw `Error: contentItemNode.contentItem is null or undefined in getNewsTags`
  }

  return contentItemNode.easySoftwareNewsTag
}
