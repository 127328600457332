import './NewsAuthor.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import React, { FunctionComponent } from 'react'
import { HeadProps } from 'gatsby'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IPuxAuthor } from 'Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import PuxRepeater from 'Root/Shared/components/PuxRepeater/PuxRepeater'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import EasySoftwareBreadcrumbs from 'Root/Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxMediaSelector from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'

const NewsTag: FunctionComponent<GenericPageProps<IPuxAuthor>> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const repeaterData = props.pageContext.newsRepeater
  const breadcrumbsData = props.pageContext.breadcrumbs

  return (
    <Layout localizedPath={pageData.localization?.localizations} footerData={footerData} headerData={headerData} pageContentItemId={pageData.contentItemId}>
      <div className='NewsAuthor'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <div className='NewsAuthor-header'>
            <div className='NewsAuthor-image'>
              <PuxMediaSelector
                {...pageData.puxMediaSelector}
                width={112}
                height={112}
                disableLazyload={true}
              />
            </div>
            <div className='NewsAuthor-content'>
              <h1 className='h2'>{pageData.displayText}</h1>
              <div className='NewsAuthor-metadata'>
                {pageData.authorJob ? (
                  <div className='NewsAuthor-job'>
                    {pageData.authorJob}
                  </div>
                ) : null}
                {(pageData.authorJob && pageData.authorLinkedIn) ? (
                  <div className='NewsAuthor-spacer'></div>
                ) : null}
                {pageData.authorLinkedIn ? (
                  <a href={pageData.authorLinkedIn} target='_blank' className='NewsAuthor-linkedin PuxSocial-logo PuxSocial-linkedin'>linkedin</a>
                ) : null}
              </div>
              <EasySoftwareEditorContainer wysiwygId={pageData.contentItemId + '-perex'} pageContentItemId={pageData.contentItemId} content={pageData.authorContent} editorPath={`newsCategoryContent,html`} />
            </div>
          </div>
          {repeaterData ? (
            <div className="NewsAuthor-grid">
              <section className="Section">
                <div className="Container">
                  <div className="Section-grid">
                    <div className="WidgetContainer WidgetContainer-padding WidgetContainer--100 Justify">
                      <PuxRepeater {...repeaterData} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default NewsTag

export function Head(props: HeadProps<never, IPageContext<IPuxAuthor>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}