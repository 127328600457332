import { WidgetPuxRepeaterType } from "../../components/widgets/WidgetPuxRepeater"
import { getRepeaterQuery } from "../../queries/repeater-queries"
import { getBuilderService } from "./puxBuilderService"
import { puxGraphqlHelper } from "./puxGraphqlHelper"

export const getRepeaterData = async (widget: WidgetPuxRepeaterType): Promise<WidgetPuxRepeaterType | undefined> => {
    const { locale } = getBuilderService()

    const getExplicitContentItemIds = (explicitIDItems): string[] => {
        const explicitIDs: string[] = []

        if (explicitIDItems?.cultureContentItems && explicitIDItems?.cultureContentItems?.length > 0) {
            explicitIDItems?.cultureContentItems.map(item => explicitIDs.push(item.contentItemId))
        }

        return explicitIDs
    }

    const termIds = widget.puxSimpleFilter.simpleFilterTermIDs.termContentItemIds
        .map((item) => `"${item}"`)
        .join(`,`)
    const contentType = widget.puxSimpleFilter.simpleFilterContentType
    const explicitContentItemIds = getExplicitContentItemIds(widget.puxSimpleFilter.simpleFilterExplicitIDs)
    const sortByIds = widget.puxSimpleFilter.simpleFilterSortByIDs
    const fallbackCulture = widget.simpleRepeaterFallbackCulture
    const pageSize = widget.simpleRepeaterPageSize || 3
    const onePage = widget.simpleRepeaterOnePageOnly

    const shouldUseExplicitItems =
        !sortByIds &&
        explicitContentItemIds &&
        explicitContentItemIds.length // explicit content items selected

    if (
        ((termIds && termIds.length) || shouldUseExplicitItems) &&
        contentType
    ) {
        const fallbackCultureString =
            fallbackCulture == undefined || !fallbackCulture.html
                ? ``
                : `, fallbackCulture: "${fallbackCulture.html}"`

        const queryTaxonomyItems = `
            query TaxonomyContentItemsQuery {
                orchard {
                    taxonomyContentItem(where: {termContentItemId_in: [${termIds}], contentType: "${contentType}", culture: "${locale.isoCode.toLowerCase()}" ${fallbackCultureString}}) {
                        contentItemId
                    }
                }
            }
        `

        const taxonomies = !shouldUseExplicitItems
            ? await puxGraphqlHelper<any>(queryTaxonomyItems)
            : undefined

        // todo: fix eslint for ternary operators !!
        /* eslint-disable indent */
        const itemIdArray = !shouldUseExplicitItems
            ? taxonomies['taxonomyContentItem'].map(
                (item) => item.contentItemId
            )
            : explicitContentItemIds
        /* eslint-disable indent */

        const query = getRepeaterQuery(
            {
                contentType: contentType,
                itemIdsArray: itemIdArray,
                limit: onePage ? pageSize : itemIdArray?.length,
                sort: !shouldUseExplicitItems,
                preferedContentLinkCulture: locale?.preferedContentLinkCulture?.toLowerCase() ?? locale.isoCode?.toLowerCase()
            }
        )

        const fullQuery = `
            query RepeaterContentItemsQuery {
                orchard {
                    ${query}
                }
            }
        `

        if (query && query.length) {
            const itemsData = await puxGraphqlHelper<unknown>(fullQuery)

            if (!itemsData) return

            const rootNodeName = Object.keys(itemsData)[0]
            widget.repeaterData = itemsData[rootNodeName]

            if (sortByIds) {
                // easiest way to sort by shorter array? indexOf returns -1 for not found, thus they are always sorted in front and reversed, so we are "de-reversing" them
                // for that, we need to reverse sorting array first, otherwise it would be sorted in opposite direction
                // should be in O(n)
                explicitContentItemIds.reverse()
            }

            if (!widget.repeaterData) return

            // sort results by preselected ids
            if (shouldUseExplicitItems || sortByIds) {
                widget.repeaterData.sort((a, b) =>
                    explicitContentItemIds.indexOf(
                        a.contentItemId
                    ) >
                        explicitContentItemIds.indexOf(
                            b.contentItemId
                        )
                        ? 1
                        : -1
                )
            }

            if (sortByIds) {
                // now the array is sorted completely in reverse, so we are changing it back to desired state
                widget.repeaterData.reverse()
            }

        }
    }

    if (widget.repeaterData?.length <= widget.simpleRepeaterPageSize) {
        widget.simpleRepeaterPagerOptions = "showall"
    }

    return widget
}