import React, { FunctionComponent, lazy, Suspense, useState } from 'react'
import { Collapse } from 'react-collapse'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { ChangeLogType } from 'Shared/utils/getChangeLogData'
import { t } from 'ttag'

const ChangeLogTableRow = lazy(() => import(`./ChangeLogTableRow`))

export interface ChangeLogTableType {
  version: string
  data: ChangeLogType[]
}

const rootClass = `change-log-table`

const ChangeLogTable: FunctionComponent<ChangeLogTableType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className='ChangeLogTable'>
      <div
        className={`ChangeLogTable-title${isOpened ? ` is-opened` : ``}`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <h2>{props.version}</h2>
      </div>
      <Collapse isOpened={isOpened}>
        <div className={`ChangeLogTable-table Table`}>
          <table>
            <tbody>
              <tr>
                <td>{t`Redmine.ChangeLog.Type`}</td>
                <td>{t`Redmine.ChangeLog.Subject`}</td>
                <td>{t`Redmine.ChangeLog.Build`}</td>
              </tr>
              {props.data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <Suspense fallback={<></>}>
                      <ChangeLogTableRow data={item} />
                    </Suspense>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  )
}

export default ChangeLogTable
