import './KnowledgeBaseCategory.scss'

import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import GoogleSearch from 'Shared/components/GoogleSearch/GoogleSearch'
import {
  GenericPageProps,
  IEasySoftwareKnowledgeBase,
  IEasySoftwareKnowledgeBaseCategory,
} from 'Shared/queries/page-queries'
import { IPageContext } from 'Shared/utils/data/buildTypedPage'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const KnowledgeBaseCategory: FunctionComponent<
  GenericPageProps<IEasySoftwareKnowledgeBase>
> = (props) => {
  const breadcrumbsData = props.pageContext?.breadcrumbs
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const widgetData = props.pageContext.widgets?.widgetZone
  const editorPath = `widgetZone,widgets`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
    >
      <div className='Container KnowledgebaseCategory'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} showAll={true} />
        <div className='KnowledgebaseCategory-header'>
          <h1>{props.pageContext.pageData.displayText}</h1>
          <GoogleSearch searchType='knowledgebase' />
        </div>
        <WidgetBuilder
          widgetBuilderData={widgetData}
          pageContentItemId={pageData.contentItemId}
          editorPath={editorPath}
        />
      </div>
    </Layout>
  )
}

export default KnowledgeBaseCategory

export function Head(
  props: HeadProps<never, IPageContext<IEasySoftwareKnowledgeBaseCategory>>
) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return (
    <PuxMetaTagsGatsby
      {...metaTags}
      localizedPaths={pageData.localization?.localizations}
    />
  )
}
